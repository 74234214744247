import React from "react";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

function Contactpage() {
  return (
    <>
      <Contact />
      <Footer />
    </>
  );
}

export default Contactpage;
