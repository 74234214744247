import React from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import assets from "../../assets/assets";

function Navbar() {
  return (
    <>
      <header>
        <nav className="header">
          <div className="logo">
            <NavLink to="/" activeClassName="active" className="logoone">
              <div className="full">
                <img srcSet={assets.bpa} alt="B.P. Alloys Limited" />
                <p>Since&nbsp;1987</p>
              </div>
              <h2>B.P.&nbsp;ALLOYS&nbsp;LIMITED</h2>
            </NavLink>
          </div>
          <input type="checkbox" id="click" />
          <label htmlFor="click" className="mainicon">
            <div className="menu">
              <i className="fa-solid fa-bars"></i>
            </div>
          </label>
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="active">
                HOME
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/about" activeClassName="active">
                ABOUT&nbsp;US
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/service" activeClassName="active">
                SERVICES
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" activeClassName="active">
                CONTACT&nbsp;US
              </NavLink>
            </li>
            <li id="eng">EN</li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
