import React from "react";
import Hero from "../components/Hero/Hero";
import Footer from "../components/Footer/Footer";

function Homepage() {
  return (
    <>
      <Hero />
      <Footer />
    </>
  );
}

export default Homepage;
