import React from "react";
import About from "../components/About/About";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

function Aboutpage() {
  return (
    <>
      <Navbar />
      <About />
      <Footer />
    </>
  );
}

export default Aboutpage;
