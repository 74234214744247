import React from "react";
import "./Service.css";
import assets from "../../assets/assets";

function Service() {
  return (
    <>
      <div className="service">
        <div className="first">
          <h1>PRIMARY&nbsp;PRODUCT</h1>

          <div>
            <li>Special Grade alloys Steel Rolled Products </li>
          </div>

          <div className="displ">
            <img srcSet={assets.fth} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.ith} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.fo} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.ft} alt="BP ALLOYS LIMITED" />
          </div>
        </div>
        <div className="second">
          <h1>END&nbsp;USES</h1>
          <div>
            <li>Induction Furnace - Steel Ingot</li>
            <li>Rolling Mill - Special Grade alloys Steel rolled products</li>
          </div>

          <div className="displ">
            <img srcSet={assets.ff} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.rt} alt="BP ALLOYS LIMITED" />{" "}
            <img srcSet={assets.it} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.io} alt="BP ALLOYS LIMITED" />
          </div>
        </div>
        <div className="three">
          <h1>MAIN&nbsp;RAW&nbsp;MATERIAL</h1>
          <div>
            <li>Iron Scrap</li>
            <li>Ferro Alloys</li>
            <li>Steel Billet/Ingot</li>
            <li>Sponge Iron</li>
          </div>

          <div className="displ">
            <img srcSet={assets.so} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.sth} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.ro} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.st} alt="BP ALLOYS LIMITED" />
          </div>
        </div>
        <div className="manu">
          <h1 className="textgradient">Manufacturing&nbsp;Process</h1>
          <p>
            <b>Induction Furnace Process : </b>A coreless induction furnace
            works similarly to a transformer, where the induction coil (primary
            coil) induces a current in the alloys steel rolled products
            (secondary coil). When an alternating current is applied, a large
            current is induced in the charge, heating it up until it melts. The
            molten steel is stirred by the magnetic field, ensuring uniform
            chemical composition and thorough mixing of any added materials.
          </p>
          <p>
            <b>Technical&nbsp;Aspects&nbsp;:</b>
          </p>
          <div>
            <li>
              <b>Raw&nbsp;Materials&nbsp;:</b> Scrap iron, sponge iron, and
              silico manganese, readily available locally.
            </li>
            <li>
              <b>Power&nbsp;: </b>Existing power load of 3.5 MW suffices for the
              expansion.
            </li>
            {/* <li>
              <b>Water : </b>Total requirement met through an existing tubewell.
            </li> */}
            <li>
              <b>Effluent&nbsp;Disposal&nbsp;: </b>Managed through advanced
              pollution control designs and processes, adhering to guidelines by
              Punjab Science and Technology.
            </li>
          </div>

          <p>
            <b>Quality&nbsp;Control&nbsp;:</b>
          </p>
          <div>
            <li>
              Manufacturing of steel flats, rounds & others to high-quality
              standards.
            </li>
            <li>
              Implementation of necessary tests and supervision by experienced
              promoters.
            </li>
            <li>
              Use of advanced testing equipment to ensure product quality.
            </li>
          </div>

          <p>
            <b>Marketing&nbsp;: </b>With an established market presence and
            strong customer relationships, BP Alloys Limited faces no challenges
            in product demand. The company’s extensive experience in marketing
            ensures effective distribution and customer satisfaction.
          </p>
        </div>
      </div>
    </>
  );
}

export default Service;
