import React from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import Service from "../components/Service/Service";

function Servicepage() {
  return (
    <>
      <Navbar />
      <Service />
      <Footer />
    </>
  );
}

export default Servicepage;
