import React from "react";
import "./Hero.css";
import Navbar from "../Navbar/Navbar";
import { NavLink } from "react-router-dom";

function Hero() {
  return (
    <>
      <div className="hero">
        <Navbar />
        <div className="overlay"></div>
        <div className="heading">
          Excellence&nbsp;in&nbsp;Alloy&nbsp;Steels, <br />
          Precision&nbsp;in&nbsp;Production!
        </div>
        {/* <div className="stats">
          <div className="stat">
            <h2 className="textgradient">60+</h2>
            Clients
          </div>
          <div className="stat">
            <h2 className="textgradient">100Cr+</h2>
            Overall Sales
          </div>
          <div className="stat">
            <h2 className="textgradient">25+</h2>
            Business Years
          </div>
        </div> */}
      </div>
      <div className="overview">
        <h1>Overview</h1>
        <p>
          BP Alloys Limited, established in 1987, specializes in manufacturing
          special grade alloys steel flats, Rounds & all other steel roll
          products. Located in Ludhiana, Punjab, the company is well-positioned
          to leverage local resources. The Company focuses on transforming raw
          iron scrap into high-quality steel products, meeting stringent
          industry standards. Catering to engineering sector, automotive sector,
          agriculture sector and all across the country.
        </p>
      </div>
      <div className="careers">
        <h2>CAREER</h2>
        <h1>Open&nbsp;Positions</h1>
        <h3>
          Discover thrilling career opportunities at B.P.&nbsp;Alloys and
          elevate your professional journey.
        </h3>
        <div className="star">
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <h3>Trusted&nbsp;by&nbsp;100+&nbsp;businesses.</h3>
        </div>
        <div className="boxes">
          <div className="box">
            <div className="position">
              <h2>Office&nbsp;Assistant</h2>
              <div className="remote">
                <h3>Remote</h3>
                <h3>Full-Time</h3>
              </div>
            </div>
            <div className="desc">
              B.P. Alloys Limited is seeking a highly organized and proactive
              Office Assistant to join our dynamic team. The ideal candidate
              will be responsible for managing day-to-day administrative tasks,
              including scheduling appointments, handling correspondence,
              maintaining office supplies, and ensuring smooth office
              operations. Excellent communication skills, proficiency in
              Microsoft Office, and a keen attention to detail are essential.
            </div>
            <NavLink to="/contact" className="apply">
              CONTACT&nbsp;US
              <i className="fa-solid fa-right-long"></i>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
