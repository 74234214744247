import React from "react";
import "./About.css";
import assets from "../../assets/assets";

function About() {
  return (
    <>
      <div className="about">
        <div className="overthree"></div>
        <h1 className="headabout textgradient">
          ALL&nbsp;ABOUT B.P.&nbsp;ALLOYS&nbsp;LIMITED!
        </h1>
        <div className="aboutus">
          <h1> Company&nbsp;Overview</h1>
          <p className="ptag">
            B. P. Alloys Limited is undergoing an expansion and modernization to
            enhance its manufacturing capabilities. This includes the
            upgradation of quality systems. The company will update all
            machinery and equipment to meet new production capacities and market
            standards. Engaging experienced professionals, BP Alloys Limited
            aims to maintain high-quality production and efficient operations.
          </p>
          <h1>History&nbsp;and&nbsp;Background</h1>
          <p className="ptag">
            Founded on November 30, 1987, BP Alloys Limited has grown to become
            a significant player in the steel manufacturing industry. The
            company’s plant in Ludhiana has seen continuous development,
            adapting to market demands and technological advancements. The
            current expansion reflects its commitment to modernization and
            quality improvement.
          </p>
        </div>
        <div className="legal">
          <h1 className="textgradient">LEGAL</h1>
          <div className="legalImg">
            <img srcSet={assets.legalOne} alt="BP ALLOYS LIMITED" />
            <img srcSet={assets.legalTwo} alt="BP ALLOYS LIMITED" />
          </div>
        </div>
        <div className="values">
          {/* <h4 className="tag">WHAT WE ASPIRE TO BE</h4> */}
          <h1 className="textgradient">OUR&nbsp;VISION</h1>
          <p className="ptag">
            We envision becoming the leading innovator in scrap steel recycling
            and steel plate production, driving circular economy principles in
            the steel industry. Our goal is to set new standards for efficiency,
            quality, and environmental stewardship in steel recycling. We strive
            to be at the forefront of technological advancements, continuously
            improving our processes to maximize resource utilization and
            minimize waste. Through our efforts, we aspire to inspire broader
            adoption of sustainable practices across the steel industry.
          </p>
          {/* <h4 className="tag">WHO WE ARE, WHAT WE DO</h4> */}
          <h1 className="textgradient">OUR&nbsp;MISSION</h1>
          <p className="ptag">
            Our company's mission is to transform scrap steel into high-quality
            steel plates, promoting sustainability while meeting industrial
            needs. We are dedicated to efficiently recycling steel waste and
            converting it into valuable products that serve various sectors of
            the economy. By doing so, we aim to reduce the environmental impact
            of steel production and contribute to a more circular and
            sustainable industry.
          </p>
          {/* <h4 className="tag">WHAT GUIDES US</h4> */}
          <h1 className="textgradient">OUR&nbsp;VALUES</h1>
          <p className="ptag">
            Our core values are the foundation of our company culture and guide
            our daily operations. We are deeply committed to sustainability,
            ensuring that our practices are environmentally responsible and
            contribute to a greener future. Quality is paramount in our work, as
            we consistently deliver superior steel plates that exceed industry
            standards. We foster a culture of innovation, encouraging continuous
            improvement in our processes and products. Safety is a top priority,
            with a focus on protecting the well-being of our employees and
            stakeholders. Finally, we operate with unwavering integrity,
            maintaining honesty and transparency in all our dealings to build
            trust with our customers, partners, and community.
          </p>
        </div>

        {/* <div className="team">
          <h1>MEET OUR TEAM !</h1>
          <div className="tboxes">
            <div className="tbox">
              <div className="bei">
                <img srcSet={assets.a} alt="BP ALLOYS LIMITED" />
                <p className="named">
                  <b>Mr. Abhishek Gupta</b>
                </p>
                <p>Managing Director</p>
              </div>
            </div>
            <div className="tbox">
              <div className="bei">
                <img srcSet={assets.s} alt="BP ALLOYS LIMITED" />
                <p className="named">
                  <b>Mrs. Samriti Gupta</b>
                </p>
                <p>Director</p>
              </div>
            </div>
            <div className="tbox">
              <div className="bei">
                <img srcSet={assets.k} alt="BP ALLOYS LIMITED" />
                <p className="named">
                  <b>Mr. Kailash Chand Sharma</b>
                </p>
                <p>Independent Director</p>
              </div>
            </div>
            <div className="tbox">
              <div className="bei">
                <img srcSet={assets.s} alt="BP ALLOYS LIMITED" />
                <p className="named">
                  <b>Mr. Sanjay Srivastava</b>
                </p>
                <p>Chief Financial Officer</p>
              </div>
            </div>
          </div>
          <div className="linefour"></div>
        </div> */}
      </div>
    </>
  );
}

export default About;
